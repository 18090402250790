<template>

    <div class="h-full w-full module flex flex-col relative">

        <loader :loading="loading" />

        <div class="h-20 w-full flex flex-row justify-start items-center">

            <span class="module-title">SL</span>

            <span class="ml-auto box-title mr-2">Select SL</span>
            <multiselect v-if="slOptions" class="w-72" label="name" v-model="slSelected" :options="slOptions" />

        </div>

        <div class="h-20 w-full flex flex-row justify-start items-center rounded-lg bg-body bg-opacity-50 mb-4 px-6">

            <div class="h-full w-full flex flex-row justify-center items-center px-2 mr-1"></div>

            <div class="h-full w-full flex flex-row justify-center items-center px-2 mr-1">
                <span class="font-semibold text-xs text-font-gray text-center">Total NET Revenue Actual</span>
            </div>

            <div class="h-full w-full flex flex-row justify-center items-center px-2 mr-1">
                <span class="font-semibold text-xs text-font-gray text-center">TNR per Total CSP FTE (In 0000s) Actual</span>
            </div>

            <div class="h-full w-full flex flex-row justify-center items-center px-2 mr-1">
                <span class="font-semibold text-xs text-font-gray text-center">TNR per Total CSP FTE (In 0000s) Actual % vs plan</span>
            </div>

            <div class="h-full w-full flex flex-row justify-center items-center px-2 mr-1">
                <span class="font-semibold text-xs text-font-gray text-center">TNR Rate per Hour Actual</span>
            </div>

            <div class="h-full w-full flex flex-row justify-center items-center px-2 mr-1">
                <span class="font-semibold text-xs text-font-gray text-center">Total Chargued hours Actual</span>
            </div>

            <div class="h-full w-full flex flex-row justify-center items-center px-2 mr-1">
                <span class="font-semibold text-xs text-font-gray text-center">Total Charged Hours Inc/(Dec) % vs Plan</span>
            </div>

            <div class="h-full w-full flex flex-row justify-center items-center px-2 mr-1">
                <span class="font-semibold text-xs text-font-gray text-center">Efective Utilization % Actual</span>
            </div>

            <div class="h-full w-full flex flex-row justify-center items-center px-2 mr-1">
                <span class="font-semibold text-xs text-font-gray text-center">FTES total CSP Actual</span>
            </div>

            <div class="h-full w-full flex flex-row justify-center items-center px-2">
                <span class="font-semibold text-xs text-font-gray text-center">Chargued hours per Total CSP FTE Actual</span>
            </div>

        </div>

        <div class="flex flex-1 min-h-0 w-full flex flex-col rounded-lg bg-body bg-opacity-50 mb-2 py-4 px-6 overflow-hidden">

            <div v-if="slSelected" class="">
                <el :element="slSelected" :sl="true"/>
            </div>

            <separator />

            <div class="h-18 flex flex-row justify-start items-center">
                <span class="module-title text-2xl">SMU</span>
            </div>

            <div v-if="smus && smus.length > 0" class="h-full w-full overflow-auto">
                <el v-for="(el,index) in smus" :key="index" :element="el"/>
            </div>

            <div v-else class="h-full w-full flex flex-col justify-center items-center">
                <span class="text-lg text-font-gray">No SMU data</span>
            </div>



        </div>

    </div>

</template>
<script>
    import { TwelveMonthsChart, RevenueDays } from '@/utils'
    import Separator from '../components/Globals/Separator.vue'
    import el from '../components/Desktop/Tnr/el.vue'
    import { state, actions } from '@/store';
    import Loader from '../components/Globals/Loader.vue';

    export default {
        components:{
            Separator,
            el,
            Loader
        },
        data() {
            return{
                slSelected: null,
                slOptions: null,
                smus: null,
                loading: false
            }   
        },
        methods: {
            loadSL(){
                this.loading = true;
                this.axios.get('projects_management/tnr_sl_competency', {params: this.params}).then(response => {
                    this.slOptions = response.data.data;
                    this.$setColorsBySector(this.slOptions)
                    this.slSelected = response.data.data[0]
                    this.smus = response.data.data[0].smus
                    this.loading = false
                })

            },
        },
        computed: {
            params() { return state.globalParams },
            rolSelected(){ return state.rolSelected },
            globalParams(){
                return state.globalParams
            }
        },
        watch:{
            slSelected(n,o){
                this.smus = n.smus
            },
            rolSelected(){
                if(state.rolSelected.role_type_link && (state.rolSelected.role_type_link == 'presidencia' || state.rolSelected.role_type_link == 'slLeader' || state.rolSelected.role_type_link == 'ROL_SL' || state.rolSelected.role_type_link == 'competencyLeader' || state.rolSelected.role_type_link == 'FSO') && state.periodSelected != 'Accumulated'){
                
                } else {
                    this.$router.push({name:'home'})
                }
            },
            globalParams(){
                if(state.rolSelected.role_type_link && (state.rolSelected.role_type_link == 'presidencia' || state.rolSelected.role_type_link == 'slLeader' || state.rolSelected.role_type_link == 'ROL_SL' || state.rolSelected.role_type_link == 'competencyLeader' || state.rolSelected.role_type_link == 'FSO') && state.periodSelected != 'Accumulated'){
                
                } else {
                    this.$router.push({name:'home'})
                }
            },
            params(){
                this.loadSL();
            }
        },
        mounted(){
            if(state.rolSelected.role_type_link && (state.rolSelected.role_type_link == 'presidencia' || state.rolSelected.role_type_link == 'slLeader' || state.rolSelected.role_type_link == 'ROL_SL' || state.rolSelected.role_type_link == 'competencyLeader' || state.rolSelected.role_type_link == 'FSO') && state.periodSelected != 'Accumulated'){
                
            } else {
                this.$router.push({name:'ProjectsBilling'})
            }

            this.loadSL();
        }
    }
</script>