<template>
  
    <div v-if="element" class="h-18 w-full flex flex-row justify-start items-center">

        <div class="h-full w-full flex flex-row justify-start items-center mr-1">
            <span class="text-xl ml-2" :class="{'font-semibold text-white': sl, 'text-font-gray': !sl}">{{element.name}}</span>
        </div>

        <div class="h-full w-full flex flex-row justify-center items-center mr-1">
            <span class="font-semibold text-center" :class="{'font-semibold text-white': sl, 'text-font-gray': !sl}">{{element.tnr | reduceBigNumbers()}}€</span>
        </div>

        <div class="h-full w-full flex flex-row justify-center items-center mr-1">
            <span class="font-semibold text-center" :class="{'font-semibold text-white': sl, 'text-font-gray': !sl}">{{element.tnr_csp | reduceBigNumbers()}}€/CSP</span>
        </div>

        <div class="h-full w-full flex flex-row justify-center items-center mr-1">
            <span class="font-semibold text-center" :class="{'text-good': element.tnr_csp_vs_plan > 0, 'text-danger': element.tnr_csp_vs_plan <= 0}">{{element.tnr_csp_vs_plan | numberFormat(2)}}%</span>
        </div>

        <div class="h-full w-full flex flex-row justify-center items-center mr-1">
            <span class="text-center font-semibold" :class="{'text-white': sl, 'text-font-gray': !sl}">{{element.tnr_rate_hour | reduceBigNumbers()}}€/h</span>
        </div>

        <div class="h-full w-full flex flex-row justify-center items-center mr-1">
            <span class="text-center font-semibold" :class="{'text-white': sl, 'text-font-gray': !sl}">{{element.tnr_charged_hour | reduceBigNumbers()}}h</span>
        </div>

        <div class="h-full w-full flex flex-row justify-center items-center mr-1">
            <span class="font-semibold text-center" :class="{'text-good': element.tnr_charged_vs_plan > 0, 'text-danger': element.tnr_charged_vs_plan <= 0}">{{element.tnr_charged_vs_plan | numberFormat(2)}}%</span>
        </div>

        <div class="h-full w-full flex flex-row justify-center items-center mr-1">
            <span class="text-center font-semibold" :class="{'text-good': element.effective_utilization > 0, 'text-danger': element.effective_utilization <= 0}">{{element.effective_utilization | numberFormat(2)}}%</span>
        </div>

        <div class="h-full w-full flex flex-row justify-center items-center mr-1">
            <span class="text-center font-semibold" :class="{'text-white': sl, 'text-font-gray': !sl}">{{element.fte_total_csp | reduceBigNumbers()}}h</span>
        </div>

        <div class="h-full w-full flex flex-row justify-center items-center">
            <span class="text-center font-semibold" :class="{'text-white': sl, 'text-font-gray': !sl}">{{element.charged_hour_total_csp_fte | reduceBigNumbers()}}h/CSP</span>
        </div>

    </div>

</template>

<script>
import { state, actions } from '@/store';

export default {
    props:['element', 'sl'],
    data(){
        return{

        }
    },
    computed:{
        legendColors() { return state.slLegendColors },
    }
}
</script>

<style>

</style>